import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CommonBanner from '../../common/commonBanner/commonBanner';
import * as Styled from '../../components/locationDetails/locationDetailsStyle';
import GoogleMapReact from 'google-map-react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import iconPinBranch from '../../images/icon-map-pin-branch.png';
import iconPinCardlock from '../../images/icon-map-pin-cardlock-um.png';
import iconPinPipeline from '../../images/icon-map-pin-cardlock-pc.png';
import iconPinBoth from '../../images/icon-map-pin-branch-cardlock.png';
import LocationMarker from '../../components/locationMarker/locationMarker';
import { LocationTypes } from '../../constants';
import BannerDefault from '../../images/banner-default.jpg';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useTranslation, I18nextProvider } from 'react-i18next';
import i18next from '../../i18next';
import PropanePopup from '../../components/propanePopup/propanePopup';
import {
  addTrailingSlash,
  isExternalUrlHref,
  isValidURLLanguage,
  isValidURLRegion,
  validNodeLocale,
} from '../../utils';
import { Helmet } from 'react-helmet';
import Cookies from 'universal-cookie';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import GlobalStyles from '../../styles/globalStyles';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ogLogo from '../../images/og-logo.jpg';

const LocationDetailsPage = ({ data, location }) => {
  const locationDataQuery = data;
  let phones;
  const [showPropaneModal, setShowPropaneModal] = React.useState(false);
  const [translation] = useTranslation();
  const date = new Date();
  const cookies = new Cookies();

  const queryParams = new URLSearchParams(location?.search);
  const regionQueryParams = queryParams.get('region')?.toUpperCase();

  typeof window !== 'undefined' &&
    regionQueryParams &&
    window?.history?.replaceState({}, document?.title, location?.pathname);

  const updateAndSetRegionCookie = () => {
    if (
      isValidURLRegion(regionQueryParams) &&
      cookies.get('region')?.toUpperCase() !== regionQueryParams
    ) {
      cookies.set('region', regionQueryParams?.toLowerCase(), {
        path: '/',
        expires: new Date(
          date.getFullYear() + 1,
          date.getMonth(),
          date.getDate()
        ),
      });
      return regionQueryParams;
    }
  };

  const updateAndSetLanguageCookie = (language) => {
    if (language && cookies.get('language') !== language) {
      cookies.set('language', language, {
        path: '/',
        expires: new Date(
          date.getFullYear() + 1,
          date.getMonth(),
          date.getDate()
        ),
      });
      return language;
    }
  };

  const selectedRegion =
    isValidURLRegion(regionQueryParams) &&
    cookies.get('region')?.toUpperCase() !== regionQueryParams
      ? updateAndSetRegionCookie()
      : cookies.get('region')?.toUpperCase()
      ? cookies.get('region')?.toUpperCase()
      : 'NB';

  const urlLanguage = location?.pathname?.split('/')?.find((lang) => lang);

  const selectedLanguage =
    isValidURLLanguage(urlLanguage) &&
    validNodeLocale(urlLanguage) &&
    cookies.get('language') !== validNodeLocale(urlLanguage)
      ? updateAndSetLanguageCookie(validNodeLocale(urlLanguage))
      : cookies.get('language')
      ? cookies.get('language')
      : 'en-US';

  const locationDataEdge =
    locationDataQuery?.allContentfulLocation?.edges?.filter(
      (edge) => edge?.node?.node_locale === selectedLanguage
    );

  let locationData;
  const res = locationDataEdge?.find((data) => data);

  res
    ? (locationData = res?.node)
    : navigate('/en/locations', { replace: true });

  const appendLanguageToURL = isValidURLLanguage(urlLanguage) && urlLanguage;

  const regionLanguageFilteredData =
    locationDataQuery?.allContentfulUltramarCommercial?.edges?.find(
      (edge) => edge?.node?.node_locale === selectedLanguage
    );
  const locationCommonData = regionLanguageFilteredData?.node?.sections?.filter(
    (region) => region?.abbreviation === 'NB'
  )[0]?.sections;

  const productSupplierList = locationCommonData?.filter(
    (section) => section?.type === 'productSupplier'
  )[0].listSection;

  const productSupplierData = productSupplierList?.filter(
    (section) => section?.type === 'productSupplierInCityContent'
  )[0];
  const alsoAvailableData = productSupplierList?.filter(
    (section) => section?.type === 'textWithoutImage'
  )[0];
  const serviceAreasData = productSupplierList?.filter(
    (section) => section?.type === 'contentText'
  )[0];

  const productSupplierImages = productSupplierList?.filter(
    (section) => section?.type === 'locationAddressProductSupplierImages'
  )[0]?.listOfImagesWithPath;
  const dieselImage = productSupplierImages?.filter(
    (image) => image?.type === 'dieselImageWithPath'
  )[0];
  const propaneImage = productSupplierImages?.filter(
    (image) => image?.type === 'propaneImageWithPath'
  )[0];
  const heatingOilImage = productSupplierImages?.filter(
    (image) => image?.type === 'heatingOilImageWithPath'
  )[0];
  const lubricantImage = productSupplierImages?.filter(
    (image) => image?.type === 'lubricantsImageWithPath'
  )[0];
  const defImage = productSupplierImages?.filter(
    (image) => image?.type === 'defImageWithPath'
  )[0];

  const servicesData = locationCommonData?.filter(
    (section) => section?.type === 'locationDetails'
  )[0]?.listSection;
  const servicesHeader = servicesData?.filter(
    (section) => section?.type === 'locationDetailsHeader'
  )[0];
  const servicesImageData = servicesData?.filter(
    (section) => section?.type === 'imagesWithoutPath'
  )[0]?.listOfImagesWithPath;

  const getMarkerIcon = (types) => {
    let markerIcon = null;
    if (types?.length > 1) {
      if (markerIcon == null) {
        markerIcon = iconPinBoth;
      }
    } else {
      types.forEach((locationType) => {
        if (locationType?.type === LocationTypes.Branch) {
          if (markerIcon == null) {
            markerIcon = iconPinBranch;
          }
        }
        if (locationType?.type === LocationTypes.UltramarCardlock) {
          if (markerIcon == null) {
            markerIcon = iconPinCardlock;
          }
        }
        if (locationType?.type === LocationTypes.PipelineCardlock) {
          if (markerIcon == null) {
            markerIcon = iconPinPipeline;
          }
        }
      });
    }
    return markerIcon;
  };
  const getIcon = (locationType) => {
    if (locationType) {
      if (locationType?.length > 1) {
        return iconPinBoth;
      } else {
        switch (locationType[0]?.type) {
          case LocationTypes.Branch:
            return iconPinBranch;

          case LocationTypes.UltramarCardlock:
            return iconPinCardlock;

          case LocationTypes.PipelineCardlock:
            return iconPinPipeline;

          default:
            return iconPinBranch;
        }
      }
    }
  };
  const products = [
    {
      boolean: locationData?.hasDiesel,
      name: translation('Diesel'),
    },
    {
      boolean: locationData?.hasPropane,
      name: translation('Propane'),
    },
    {
      boolean: locationData?.hasHeatingOil,
      name: translation('HeatingOil'),
    },
    {
      boolean: locationData?.hasLubricants,
      name: translation('Lubricants'),
    },
    {
      boolean: locationData?.hasDef,
      name: translation('DefShort'),
    },
  ];
  const offerings = [
    {
      boolean: locationData?.hasHeatingOil,
      name: translation('HeatingOil'),
    },
    {
      boolean: locationData?.hasDiesel,
      name: translation('Diesel'),
    },
    {
      boolean: locationData?.hasMarkedDiesel,
      name: translation('MarkedDiesel'),
    },
    {
      boolean: locationData?.hasGasoline,
      name: translation('Gasoline'),
    },
    {
      boolean: locationData?.hasPropane,
      name: translation('Propane'),
    },
    {
      boolean: locationData?.hasLubricants,
      name: translation('Lubricants'),
    },
    {
      boolean: locationData?.hasHeatingEquipment,
      name: translation('HeatingEquipment'),
    },
    {
      boolean: locationData?.hasDef,
      name: translation('Def'),
    },
    {
      boolean: locationData?.hasShowers,
      name: translation('Showers'),
    },
    {
      boolean: locationData?.hasRestrooms,
      name: translation('Restroom'),
    },
    {
      boolean: locationData?.hasWifi,
      name: translation('Wi-Fi'),
    },
    {
      boolean: locationData?.hasParking,
      name: translation('Parking'),
    },
    {
      boolean: locationData?.hasRestaurant,
      name: translation('Restaurant'),
    },
    {
      boolean: locationData?.hasConvenienceStore,
      name: translation('ConvenienceStore'),
    },
    {
      boolean: locationData?.hasLunchCounter,
      name: translation('LunchCounter'),
    },
    {
      boolean: locationData?.hasDriversLounge,
      name: translation('DriversLounge'),
    },
    {
      boolean: locationData?.hasMultiServices,
      name: translation('MultiServices'),
    },
  ];
  const availableProducts = [
    {
      boolean: locationData?.hasGasoline,
      name: translation('Gasoline'),
    },
    {
      boolean: locationData?.hasMarkedDiesel,
      name: translation('MarkedDiesel'),
    },
    {
      boolean: locationData?.hasHeatingEquipment,
      name: translation('HeatingEquipment'),
    },
  ];
  const serviceAreas = locationData?.serviceArea?.serviceArea?.split(';');
  const [lat, setLat] = useState(parseFloat(locationData?.latitude) || 0);
  const [lng, setLng] = useState(parseFloat(locationData?.longitude) || 0);
  const [currentMarker, setCurrentMarker] = useState('');
  const [zoom, setZoom] = useState(10);

  const StringGenerator = (object) => {
    let header = '';
    let length = 0;
    object.map((item) => {
      if (item?.boolean) {
        if (length !== 0) {
          header += ', ' + item?.name;
          length++;
        } else {
          header += item?.name;
          length++;
        }
      }
    });
    return header;
  };

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.MiddleText>{children}</Styled.MiddleText>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Styled.SubHeader>{children}</Styled.SubHeader>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  const servicesMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ServicesText>{children}</Styled.ServicesText>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  React.useEffect(() => {
    //setLoaded(true);
    AOS.init();
    AOS.refresh();
  }, []);

  const { i18n } = useTranslation();

  const changeLanguageHandler = (lang) => {
    if (typeof window === 'undefined') {
      i18n.changeLanguage(lang === 'fr-CA' ? 'fr' : 'en');
    }
  };

  return (
    <>
      {changeLanguageHandler(selectedLanguage)}
      <I18nextProvider i18n={i18next}>
        <Helmet>
          <title>{locationData?.pageTitle + ' | Ultramar'}</title>
          <link
            rel="alternate"
            href={addTrailingSlash(
              process.env.GATSBY_DOMAIN_URL +
                '/en/locations/details/' +
                locationData?.urlSlug
            )}
            hreflang="en-ca"
          ></link>
          <link
            rel="alternate"
            href={addTrailingSlash(
              process.env.GATSBY_DOMAIN_URL +
                '/fr/sites/details/' +
                locationData?.urlSlug
            )}
            hreflang="fr-ca"
          ></link>
          <meta property="og:image" content={ogLogo}></meta>
          <meta property="twitter:image" content={ogLogo}></meta>
        </Helmet>
        <GlobalStyles />
        <Header
          englishSlug={addTrailingSlash(
            '/en/locations/details/' + locationData?.urlSlug
          )}
          frenchSlug={addTrailingSlash(
            '/fr/sites/details/' + locationData?.urlSlug
          )}
        />
        <CommonBanner
          Header={locationData?.pageTitleDetails}
          ImageUrl={BannerDefault}
        />
        <Styled.SectionContainer>
          <Row className="g-0">
            <Styled.TopLeftCol xs={12} xl={6}>
              <Styled.TopHeader>
                <Styled.IconImage
                  src={getIcon(locationData?.pinType)}
                  alt="Pin Icon"
                />
                {locationData?.location}
              </Styled.TopHeader>
              <Row className="g-0">
                <Col xs={12} md={6}>
                  <Styled.ParaText>
                    {locationData?.address1}
                    <br />
                    {locationData?.city},&nbsp;{locationData?.province}&nbsp;
                    {locationData?.postalCode}
                    <br />
                    <br />
                    {
                      ((phones = locationData?.phone.split(' ')),
                      phones?.map((phone, index) => (
                        <Styled.LinkStyle
                          key={index}
                          href={`tel:${phone}`}
                          tabindex="0"
                        >
                          {phone}
                        </Styled.LinkStyle>
                      )))
                    }
                    <br />
                    <Styled.LinkStyle
                      target="_blank"
                      href={`https://www.google.com/maps/dir/?api=1&destination=
                      ${locationData?.address1}, ${locationData?.location}, ${locationData?.province}, ${locationData?.postalCode}`}
                    >
                      {translation('GetDirections')}
                    </Styled.LinkStyle>
                  </Styled.ParaText>
                  <Styled.ParaText>
                    <Styled.AnchorButton
                      href={addTrailingSlash(
                        process.env.GATSBY_DOMAIN_URL +
                          (appendLanguageToURL === 'fr' ? '/fr' : '/en') +
                          '/contact'
                      )}
                    >
                      {translation('ContactUs')}
                    </Styled.AnchorButton>
                  </Styled.ParaText>
                </Col>
                <Col>
                  <div style={{ marginBottom: '40px' }}>
                    <Styled.Header3>{translation('Hours')}</Styled.Header3>
                    {(locationData?.pinType.length > 1 ||
                      (locationData?.pinType.length === 1 &&
                        locationData?.pinType[0].type !==
                          LocationTypes.UltramarCardlock &&
                        locationData?.pinType[0].type !==
                          LocationTypes.PipelineCardlock)) && (
                      <>
                        <strong>{translation('Branch')}:</strong>
                        <br />
                        {locationData?.branchHours}
                        <br />
                      </>
                    )}
                    {locationData?.cardlockHours && (
                      <>
                        <strong>{translation('Cardlock')}: </strong>
                        {locationData?.cardlockHours}
                      </>
                    )}
                  </div>
                  <Styled.Header3>{translation('Offerings')}</Styled.Header3>
                  {StringGenerator(offerings)}
                </Col>
              </Row>
            </Styled.TopLeftCol>
            <Styled.TopRightCol className="px-0" xs={12} xl={6}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.GATSBY_GOOGLE_MAP_APIKEY,
                  libraries: ['places', 'geometry'],
                }}
                center={{ lat, lng }}
                zoom={zoom}
                yesIWantToUseGoogleMapApiInternals
              >
                {locationDataEdge?.map((location, index) => (
                  <LocationMarker
                    key={index}
                    id={location?.node?.id}
                    currentMarker={currentMarker}
                    lat={location?.node?.latitude}
                    showServices={true}
                    lng={location?.node?.longitude}
                    node={location?.node}
                    src={getMarkerIcon(location?.node?.pinType)}
                    onMarkerClick={(data) => {
                      setCurrentMarker(data?.id);
                      setLat(parseFloat(data?.lat));
                      setLng(parseFloat(data?.lng));
                    }}
                  />
                ))}
              </GoogleMapReact>
            </Styled.TopRightCol>
          </Row>
        </Styled.SectionContainer>
        <Styled.SectionContainerMiddle>
          <Row>
            <Styled.MiddleLeftCol
              xs={12}
              xl={locationData?.serviceArea ? 6 : 12}
            >
              <Styled.Header style={{ textAlign: 'left' }}>
                {productSupplierData?.header
                  .replace('{Products}', StringGenerator(products))
                  .replace('{city}', locationData?.location)}
              </Styled.Header>
              {productSupplierData?.contentDetails &&
                documentToReactComponents(
                  JSON.parse(productSupplierData?.contentDetails?.raw),
                  optionsMainStyle
                )}
              {locationData?.hasDiesel && (
                <Styled.AnchorStyleInternal
                  to={
                    isExternalUrlHref(dieselImage?.path)
                      ? dieselImage?.path
                      : addTrailingSlash(
                          process.env.GATSBY_DOMAIN_URL + dieselImage?.path
                        )
                  }
                >
                  <Styled.ProductsImageStyle
                    src={dieselImage?.image?.file.url}
                  />
                  {dieselImage?.title}
                </Styled.AnchorStyleInternal>
              )}
              {locationData?.hasPropane && (
                <Styled.AnchorStyle
                  onClick={() => {
                    setShowPropaneModal(true);
                  }}
                >
                  <Styled.ProductsImageStyle
                    src={propaneImage?.image?.file?.url}
                  />
                  {propaneImage?.title}
                </Styled.AnchorStyle>
              )}
              {locationData?.hasHeatingOil && (
                <Styled.AnchorStyleInternal
                  to={
                    isExternalUrlHref(heatingOilImage?.path)
                      ? heatingOilImage?.path
                      : addTrailingSlash(
                          process.env.GATSBY_DOMAIN_URL + heatingOilImage?.path
                        )
                  }
                >
                  <Styled.ProductsImageStyle
                    src={heatingOilImage?.image?.file?.url}
                  />
                  {heatingOilImage?.title}
                </Styled.AnchorStyleInternal>
              )}
              {locationData?.hasLubricants && (
                <Styled.AnchorStyleInternal
                  to={
                    isExternalUrlHref(lubricantImage?.path)
                      ? lubricantImage?.path
                      : addTrailingSlash(
                          process.env.GATSBY_DOMAIN_URL + lubricantImage?.path
                        )
                  }
                >
                  <Styled.ProductsImageStyle
                    src={lubricantImage?.image?.file?.url}
                  />
                  {lubricantImage?.title}
                </Styled.AnchorStyleInternal>
              )}
              {locationData?.hasDef && (
                <Styled.AnchorStyleInternal>
                  <Styled.ProductsImageStyle src={defImage?.image?.file?.url} />
                  {defImage?.title}
                </Styled.AnchorStyleInternal>
              )}
              {StringGenerator(availableProducts) && alsoAvailableData && (
                <Styled.ParaTextSmall>
                  {documentToReactComponents(
                    JSON.parse(
                      alsoAvailableData?.contentDetails?.raw?.replace(
                        '{otherProducts}',
                        StringGenerator(availableProducts)
                      )
                    ),
                    optionsMainStyle
                  )}
                </Styled.ParaTextSmall>
              )}
            </Styled.MiddleLeftCol>
            {locationData?.serviceArea && (
              <Styled.MiddleRightCol xs={12} xl={6}>
                <Styled.Header style={{ textAlign: 'left' }}>
                  {serviceAreasData?.header?.replace(
                    '{prductList}',
                    StringGenerator(products)
                  )}
                </Styled.Header>
                <Styled.ServiceAreasList>
                  {serviceAreas?.map((item, index) => (
                    <Styled.ListItems key={index}>{item}</Styled.ListItems>
                  ))}
                </Styled.ServiceAreasList>
              </Styled.MiddleRightCol>
            )}
          </Row>
        </Styled.SectionContainerMiddle>
        <Styled.SectionContainer>
          <Styled.Header>
            {servicesHeader?.header
              ?.replace('{Products}', StringGenerator(products))
              ?.replace('{city}', locationData?.location)}
          </Styled.Header>
          <Row className="g-0">
            {servicesImageData?.map((item, index) => (
              <Styled.ServicesCol key={index} xs={12} md={6} xl={3}>
                <Styled.ServicesImage
                  src={item?.image?.file?.url}
                  alt={item?.title}
                />
                <Styled.SubHeader>{item?.title}</Styled.SubHeader>
                {item?.richText &&
                  documentToReactComponents(
                    JSON.parse(item?.richText?.raw),
                    servicesMainStyle
                  )}
              </Styled.ServicesCol>
            ))}
          </Row>
        </Styled.SectionContainer>
        {showPropaneModal && <PropanePopup showModal={setShowPropaneModal} />}
        <Footer />
      </I18nextProvider>
    </>
  );
};
export default LocationDetailsPage;

export const pageQuery = graphql`
  query locationDetailsDataMyQuery1($pageSlug: String!, $nodeLocale: String!) {
    allContentfulLocation(
      filter: {
        brand: { brand: { in: ["Ultramar", "Pipeline Commercial"] } }
        urlSlug: { eq: $pageSlug }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      edges {
        node {
          node_locale
          address1
          branchHours
          cardlockHours
          branchManager
          id
          location
          city
          province
          urlSlug
          postalCode
          latitude
          longitude
          phone
          type {
            type
          }
          pinType {
            type
          }
          summerHours
          serviceArea {
            serviceArea
          }
          hasHeatingOil
          hasDiesel
          hasGasoline
          hasPropane
          hasLubricants
          hasHeatingEquipment
          hasDef
          hasMarkedDiesel
          hasRestaurant
          hasRestrooms
          hasShowers
          hasWifi
          hasParking
          hasMultiServices
          hasLunchCounter
          hasDriversLounge
          hasConvenienceStore
          pageTitle
          brand {
            brand
            creditColour
            creditText {
              creditText
            }
            creditLogo {
              file {
                url
              }
            }
          }
          pageTitleDetails
        }
      }
    }
    allContentfulUltramarCommercial(
      filter: { identifier: { type: { eq: "locationDetails" } } }
    ) {
      edges {
        node {
          name
          title
          slug
          node_locale
          identifier {
            type
          }
          metadata
          sections {
            ... on ContentfulRegions {
              title
              abbreviation
              sections {
                ... on ContentfulGenericSection {
                  id
                  title
                  type
                  listSection {
                    ... on ContentfulContent {
                      id
                      title
                      type
                      header
                      contentDetails {
                        raw
                      }
                    }
                    ... on ContentfulListOfImageWithPath {
                      id
                      title
                      type
                      listOfImagesWithPath {
                        title
                        type
                        image {
                          file {
                            url
                          }
                        }
                        path
                        richText {
                          raw
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
